import moment from 'moment'
import 'moment/locale/pt-br'
import { COUNTRIES } from './constants'
import { formatNumberToLocaleString } from './helpers'
moment.locale('pt-br')

const raceEvaluation = value => {
  switch (value) {
    case 'white':
      return 0
    case 'black':
      return 1
    case 'brown':
      return 2
    case 'yellow':
      return 3
    case 'indigenous':
      return 4
    default:
      return null
  }
}

export const formatParams = obj => {
  const {
    long,
    lat,
    timezone,
    startDate,
    endDate,
    age,
    symptoms,
    address,
    order,
    male,
    female,
    indifferent,
    adult,
    kids,
    couple,
    adolescent,
    elderly,
    indifferentAvailable,
    available_in_the_morning,
    available_in_the_afternoon,
    available_at_night,
    accept_students,
    accept_depression_free_consultation,
    accept_partnerships,
    partnership,
    max_price,
    min_price,
    skills,
    page,
    count,
    already_appeared,
    seo_search,
    nameProfessional,
    languages_code,
    race,
    contract_id,
    contractId
  } = obj ?? {}

  return {
    location: {
      long: !isNaN(parseFloat(long)) ? parseFloat(long) : null,
      lat: !isNaN(parseFloat(lat)) ? parseFloat(lat) : null
    },
    timezone,
    date_from: startDate ? moment(startDate).format('YYYYMMDD') : null,
    date_to: endDate ? moment(endDate).format('YYYYMMDD') : null,
    age,
    symptoms,
    address,
    order,
    male,
    female,
    indifferent,
    adult,
    kids,
    couple,
    adolescent,
    elderly,
    indifferentAvailable,
    available_in_the_morning,
    available_in_the_afternoon,
    available_at_night,
    accept_students,
    accept_depression_free_consultation,
    accept_partnerships,
    partnership,
    max_price,
    min_price,
    skill: skills,
    page,
    count: count || 5,
    already_appeared,
    seo_search,
    nameProfessional,
    languages_code,
    race: race ? raceEvaluation(race) : null,
    contract_id: contract_id ?? contractId ?? ''
  }
}

export const rad = x => {
  return (x * Math.PI) / 180
}

// TODO: refactor/stop using var and document the code to be more readable
export const getDistance = (p1, p2) => {
  var R = 6378137
  var dLat = rad(p2.lat - p1.lat)
  var dLong = rad(p2.lng - p1.lng)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d / 1000 // returns in KM
}

const setDefaultValues = psychologist => {
  const defaultRatingArray = Array.from({ length: 5 }, (_, i) => i)
  return {
    ...psychologist,
    appointments: [],
    rating: {
      total: Math.round(psychologist.rating.total),
      array: defaultRatingArray
    }
  }
}

const truncateMiniCv = psychologist => {
  const MAX_LENGTH = 240
  return {
    ...psychologist,
    mini_cv: psychologist.mini_cv.substring(0, MAX_LENGTH) + ' ...'
  }
}

const getAppointments = psychologist => {
  if (!psychologist.schedule) {
    return psychologist
  }

  const appointments = Object.keys(psychologist.schedule).map(date => ({
    dayName: moment(date).format('ddd'),
    day: moment(date).format('DD[/]MM'),
    hours: psychologist.schedule[date]
  }))

  return {
    ...psychologist,
    appointments
  }
}

export const loadingGrid = results => {
  const updatedResults = results.map(psychologist => {
    let updatedPsychologist = setDefaultValues(psychologist)
    updatedPsychologist = truncateMiniCv(updatedPsychologist)
    updatedPsychologist = getAppointments(updatedPsychologist)
    return updatedPsychologist
  })

  return updatedResults
}

export const compareStrings = (a, b) => {
  a = a.toLowerCase()
  b = b.toLowerCase()

  return a < b ? -1 : a > b ? 1 : 0
}

export const getCountryCodes = () => {
  var countries = []
  var countryCodes = []
  for (var i = 0; i < COUNTRIES.length; i++) {
    countries[i] = { name: '', country_code: '' }
    countries[i].name = COUNTRIES[i].nome
    countries[i].country_code = COUNTRIES[i].fone
  }
  return countries.sort(function(a, b) {
    return compareStrings(a.name, b.name)
  })
}

export const getCookie = name => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length == 2)
    return parts
      .pop()
      .split(';')
      .shift()
}

export const formatDate = date => {
  return `${date.slice(6, 8)}/${date.slice(4, 6)}/${date.slice(0, 4)}`
}

export const formatDateToServer = date => {
  if (date !== undefined && date !== null && date !== '' && date.length === 10)
    return `${date.split('/')[2]}${date.split('/')[1]}${date.split('/')[0]}`
  return date
}

export const dataURLToBlob = dataURI => {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

export const getUrlParams = prop => {
  let params = {}
  if (!process.browser) return
  const search = window.location.href.slice(window.location.href.indexOf('?') + 1)

  const definitions = search.split('&')

  definitions.forEach(function(val, key) {
    var parts = val.split('=', 2)
    const decodedPart0 = decodeURIComponent(parts[0])
    const decodedPart1 = decodeURIComponent(parts[1])

    params[decodedPart0] = decodedPart1
  })

  return prop && prop in params ? params[prop] : params
}

export const hex2rgba = (hex, opacity) => {
  const hexa = hex.replace('#', '')
  const r = parseInt(hexa.substring(0, hexa.length / 3), 16)
  const g = parseInt(hexa.substring(hexa.length / 3, (2 * hexa.length) / 3), 16)
  const b = parseInt(hexa.substring((2 * hexa.length) / 3, (3 * hexa.length) / 3), 16)
  return `rgba(${r},${g},${b}, ${opacity / 100})`
}

export const formatEventsToCalendar = events =>
  events.map(event => {
    const start = new Date(
      moment({
        year: event.date.slice(0, 4),
        month: parseInt(event.date.slice(4, 6)) - 1,
        day: event.date.slice(6, 8),
        hour: parseInt(event.time.split(':')[0]),
        minute: parseInt(event.time.split(':')[1])
      })
    )
    let end = new Date(moment(start).add(event.duration, 'minutes'))
    if (moment(end).format('HH:mm') === '00:00') {
      end = new Date(moment(start).add(event.duration - 1, 'minutes'))
    }
    return {
      ...event,
      start,
      full_day:
        moment(start).format('HH:mm') === '00:00' && moment(end).format('HH:mm') === '23:59',
      allDay: false,
      end
    }
  })

export const formatAppointmentDates = (dates, schedule) => {
  return dates.map(_date => {
    const { weekday, timestamp } = _date
    const date = moment.utc(timestamp).format('D MMM')
    const dayName = weekday.slice(0, 3)
    const dayNumber = moment.utc(timestamp).format('D')
    const day = moment.utc(timestamp).format('DD/MM')
    const hours = schedule[timestamp]
    return { dayName, date, dayNumber, day, hours }
  })
}
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const translateStatus = status => {
  switch (status) {
    case 'paid':
      return 'Pago'
    case 'cancelled':
      return 'Cancelado'
    case 'cancelled_by_patient':
      return 'Cancelado pelo paciente'
    case 'cancelled_by_professional':
      return 'Cancelado pelo profissional'
    case 'lack_of_payment':
      return 'Problemas com a confirmação do pagamento. A consulta foi cancelada.'
    case 'awaiting_payment':
      return 'Aguardando pagamento'
    case 'successfully_scheduled':
      return 'Agendado com sucesso'
    case 'abandoned':
      return 'Consulta abandonada'
    case 'bank_slip_generated':
      return 'Boleto gerado'
    case 'cancel_requested':
      return 'Consulta cancelada após prazo.'
    case 'completed':
      return 'Consulta completa'
    case 'no_show':
      return 'Paciente não compareceu'
    case 'cancel_denied':
      return 'Consulta cancelada após o prazo'
    case 'cancel_accepted':
      return 'Consulta cancelada após o prazo.'
    default:
      return status
  }
}

export const isString = value => typeof value === 'string'

export const isEmptyObject = object => !Object.keys(object).filter(key => object[key]).length

export const formatSummaryData = data => {
  return {
    ...data,
    ...(data?.total && {
      total: {
        ...data.total,
        value: formatNumberToLocaleString(data.total.value)
      }
    }),
    ...(data?.fees && {
      fees: {
        ...data.fees,
        value: formatNumberToLocaleString(data.fees.value)
      }
    }),
    ...(data?.transferred && {
      transferred: {
        ...data.transferred,
        value: formatNumberToLocaleString(data.transferred.value)
      }
    }),
    ...(data?.in_release && {
      in_release: {
        ...data.in_release,
        value: formatNumberToLocaleString(data.in_release.value)
      }
    }),
    ...(data?.discounts && {
      discounts: {
        ...data.discounts,
        value: formatNumberToLocaleString(data.discounts.value)
      }
    }),
    ...(data?.invoiced && {
      invoiced: {
        ...data.invoiced,
        value: formatNumberToLocaleString(data.invoiced.value)
      }
    }),
    ...(data?.invoiced_by_vittude && {
      invoiced_by_vittude: {
        ...data.invoiced_by_vittude,
        value: formatNumberToLocaleString(data.invoiced_by_vittude.value)
      }
    }),
    ...(data?.invoiced_by_patient && {
      invoiced_by_patient: {
        ...data.invoiced_by_patient,
        value: formatNumberToLocaleString(data.invoiced_by_patient.value)
      }
    }),
    ...(data?.subtotal && {
      subtotal: {
        ...data.subtotal,
        value: formatNumberToLocaleString(data.subtotal.value)
      }
    })
  }
}

export const filterObjectProperties = list => {
  const filteredEntries = Object.entries(list).filter(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return typeof value === 'string' && value !== ''
  })

  const filteredObject = filteredEntries.reduce((result, [key, value]) => {
    result[key] = value
    return result
  }, {})

  return filteredObject
}

const isValidUUID = uuid => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(uuid)
}

export const sanitizeUUID = str => {
  let aux_one = ''
  let aux_five = ''

  const groups = str.split('-')
  if (groups?.length == 1) {
    // only validates entries with hyphens
    return str
  }
  const [one, two, three, four, five] = groups

  // Removes string before UUID
  if (one?.length > 8) {
    aux_one = one.slice(-8)
  } else {
    aux_one = one
  }

  // Removes string after UUID
  if (five?.length > 12) {
    aux_five = five.slice(0, 12)
  } else {
    aux_five = five
  }

  const uuid = [aux_one, two, three, four, aux_five]

  const uuidStr = uuid.join('-')

  return isValidUUID(uuidStr) ? uuidStr : null
}

export const removeGAPrefix = str => {
  // GA Prefixes: "GS1.1." || "GA1.1."
  return str?.slice(6) || str
}

export const formatGASessionId = str => {
  const strWithOutPrefix = removeGAPrefix(str)
  const dotIndex = strWithOutPrefix?.indexOf('.')
  return dotIndex !== -1 ? strWithOutPrefix?.slice(0, dotIndex) : str
}

export const formatTodayOrYesterdayOrFullDate = inputDate => {
  const inputDateObj = new Date(
    parseInt(inputDate.slice(0, 4)),
    parseInt(inputDate.slice(4, 6)) - 1,
    parseInt(inputDate.slice(6, 8))
  )

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  if (+inputDateObj === +today) return 'Hoje'
  if (+inputDateObj === +yesterday) return 'Ontem'

  return inputDateObj.toLocaleDateString('pt-BR')
}

export const formatFileToBase64 = (file, validFormats = ['png', 'jpg', 'jpeg', 'pdf']) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB
  const fileExtension = file.name
    .split('.')
    .pop()
    .toLowerCase()
  return new Promise((resolve, reject) => {
    if (!validFormats.includes(fileExtension)) {
      reject(
        new Error(
          `Arquivos no formato "${fileExtension}" não são suportados. Os formatos aceitos são: ${validFormats.join(
            ', '
          )}.`
        )
      )
      return
    }

    if (file.size > MAX_FILE_SIZE) {
      reject(
        new Error(
          'O arquivo excede o limite máximo permitido de 10 MB. Recomendamos utilizar ferramentas gratuitas para reduzir o tamanho do arquivo e tentar novamente.'
        )
      )
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const base64 = reader.result.split(',')[1] // Remove o prefixo "data:image/..."
      resolve(base64)
    }

    reader.onerror = error => {
      reject(`Erro ao processar o arquivo: ${error.message}`)
    }

    reader.readAsDataURL(file)
  })
}
