import Router from 'next/router'
import moment from 'moment'
import axios from 'axios'
import { parseCookies } from 'nookies'

import { getPaymentMethodsInfo } from '../services/professionalService'
import { removeGAPrefix, formatGASessionId } from 'utils/formatters'

import { toast } from 'react-toastify'

Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

const areAllStringCharacterEqual = value => /^(.)\1+$/.test(value)

export function validateCNPJ(cnpj) {
  if (!cnpj) return false

  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (areAllStringCharacterEqual(cnpj)) return false

  if (cnpj.length !== 14) return false

  let size = 12
  let documentNumber = cnpj.substring(0, size)
  let digits = cnpj.substring(size)
  let sum = 0
  let position = size - 7
  for (let i = size; i >= 1; i--) {
    sum += documentNumber.charAt(size - i) * position--
    if (position < 2) position = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != digits.charAt(0)) return false
  size = size + 1
  documentNumber = cnpj.substring(0, size)
  sum = 0
  position = size - 7
  for (let i = size; i >= 1; i--) {
    sum += documentNumber.charAt(size - i) * position--
    if (position < 2) position = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != digits.charAt(1)) return false

  return true
}

export const validateCpf = cpf => {
  cpf = cpf.replace(/\./g, '')
  cpf = cpf.replace(/\-/g, '') // eslint-disable-line

  let Residue
  let Sum
  Sum = 0
  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }

  for (let i = 1; i <= 9; i++) {
    Sum = Sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  Residue = (Sum * 10) % 11
  if (Residue === 10 || Residue === 11) Residue = 0
  if (Residue !== parseInt(cpf.substring(9, 10))) return false
  Sum = 0
  for (let i = 1; i <= 10; i++) {
    Sum = Sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  Residue = (Sum * 10) % 11

  if (Residue === 10 || Residue === 11) Residue = 0
  if (Residue !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export const groupBy = (xs, f) => {
  return xs.reduce(
    (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), // eslint-disable-line
    []
  )
}

export const getYouTubeVideoId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/ // eslint-disable-line
  const match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return 'error'
  }
}

export const copyToClipboard = element => {
  const link = element.href
  const textarea = document.createElement('textarea')
  textarea.value = link
  textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
  document.body.appendChild(textarea)
  textarea.select()
  try {
    return document.execCommand('copy') // Security exception may be thrown by some browsers.
  } catch (ex) {
    console.warn('Não foi possível copiar.', ex)
    return false
  } finally {
    document.body.removeChild(textarea)
  }
}

export const debounce = (fn, time) => {
  let timeout

  return function() {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export const openMap = professional => {
  const name = professional.name.replace(/ /g, '+')
  const latitude = professional.location?.lat ?? professional.distance.latitude
  const longitude = professional.location?.lon ?? professional.distance.longitude
  const distance = latitude && longitude ? `${latitude},${longitude}` : ''

  const url = `https://www.google.com/maps/search/?api=1&query=${distance}&query=${name}`
  window.open(url, '_blank')
}

export const navHeight = () => {
  const banner = process.browser && document.getElementById('header-banner')

  if (banner) {
    process.browser && document.querySelector('#nav-bar')?.clientHeight
  } else {
    process.browser && document.querySelector('#nav-bar')
      ? document.querySelector('#nav-bar').clientHeight
      : '87px'
  }
}

export const getUrlFromReq = req => {
  if (typeof window !== 'undefined') {
    const onServer = req !== undefined
    const path = onServer ? req.url : window.location.href
    const location = !onServer ? '' : `${process.env.VITTUDE_OFFLINE_URL}`
    return location + path
  }
  return `${process.env.VITTUDE_OFFLINE_URL}${req.url}`
}

export const isUserBrowserSafari = () =>
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1

export const isUserBrowserInternetExplorer = () =>
  navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1

export const isUserBrowserIosChrome = () => navigator.userAgent.match('CriOS')

export const getSocialThumbByBrowser = user => {
  if (typeof window === 'undefined') {
    return user.social_thumb
  }

  if (Object.keys(user.social_photos).length === 0) return user.social_thumb
  if (isUserBrowserSafari() || isUserBrowserInternetExplorer() || isUserBrowserIosChrome())
    return user.social_photos.png
  return user.social_photos.webp
}

export const getSocialThumbMatchMaker = ({ webp, png }) => {
  if (isUserBrowserSafari() || isUserBrowserInternetExplorer() || isUserBrowserIosChrome())
    return png
  return webp
}

export const passwordValidateRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
)

export const checkIsMobile = width => typeof window !== 'undefined' && window.innerWidth < width

export const isDeviceMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

export const getRemarketingCoupon = () => {
  try {
    const couponStr = localStorage.getItem('rmkt_coupon')

    if (!couponStr) return null

    const coupon = JSON.parse(couponStr)
    const now = new Date()
    if (now.getTime() > coupon.expiry) {
      localStorage.removeItem('rmkt_coupon')
      return null
    }
    return coupon.value
  } catch (error) {
    console.log(error)
  }
}

export const firstCapitalLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const getDownloadFile = (response, fileName) => {
  const blobFile = new Blob([response.data], { type: response.headers['content-type'] })
  const downloadUrl = window.URL.createObjectURL(blobFile)
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const convertToDecimalsWhenNeeded = value => {
  const valueNumber = Number(value)
  return valueNumber % 1 !== 0 ? valueNumber.toFixed(2) : valueNumber
}

export const isCnpj = value => {
  value = value.replace(/\./g, '')
  value = value.replace(/\-/g, '')

  return value.length > 11
}

export const copyStringToClipboard = string => {
  const element = document.createElement('textarea')
  element.value = string
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
}

export const setUtmTracking = () => {
  try {
    const urlPath = Router.router.asPath
    const hasUtmQuery = urlPath.includes('utm_')
    if (hasUtmQuery) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Sao_Paulo'
      const utmTracking = {
        time: `${moment().format('YYYY-MM-DD HH:mm')} ${getTimeZoneUTC()}`,
        url: `${process.env.VITTUDE_OFFLINE_URL}${urlPath}`,
        timezone: timezone
      }

      const utmTrackingArray = JSON.parse(localStorage.getItem('utm-tracking')) ?? []

      const utmTrackingData = [...utmTrackingArray, utmTracking]
      localStorage.setItem('utm-tracking', JSON.stringify(utmTrackingData))
    }
  } catch (error) {
    console.log(error)
  }
}

const getTimeZoneUTC = () => {
  const offset = new Date().getTimezoneOffset()
  const convertAbsolute = Math.abs(offset)
  const convertUtcFirst = `00${Math.floor(convertAbsolute / 60)}`
  const convertUtcLast = `00${convertAbsolute % 60}`
  const utcResult = `${convertUtcFirst.slice(-2)}:${convertUtcLast.slice(-2)}`

  return offset < 0 ? `UTC+${utcResult}` : `UTC-${utcResult}`
}

export const getTotalArticles = async hash => {
  const blogCountUrl = `https://www.vittude.com/blog/wp-json/vittude/rest/author/count?hash=${hash}`
  try {
    const { data } = await axios.get(blogCountUrl)
    return data
  } catch (error) {
    return 0
  }
}

export const formatNumberToWeekDays = numbersArray =>
  numbersArray.map(number => ({
    label: moment()
      .day(number + 1)
      .format('dddd'),
    value: number
  }))

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Sao_Paulo'
}

export const getNewestDate = dates => {
  const momentDate = dates.map(date => moment(date))

  return moment.max(momentDate)
}

export const isExpired = date => moment().isAfter(moment(date), 'day')

export const roundedHour = hour => {
  const remainder = 30 - (hour.minute() % 30)

  if (remainder === 30) return hour

  return moment(hour).add(remainder, 'minutes')
}

export const getSessionStorageItem = storedData => {
  try {
    return typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem(storedData))
  } catch (error) {
    console.log(error)
  }
}

export const getDataContractId = () =>
  typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem('dataContractId'))

export const setDataContractId = presignup => {
  return (
    typeof window != 'undefined' &&
    sessionStorage.setItem('dataContractId', JSON.stringify(presignup))
  )
}
export const deleteDataContractId = () =>
  typeof window !== 'undefined' && sessionStorage.removeItem('dataContractId')

export const formatForCurrency = value =>
  value && value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const formatNumberToLocaleString = number =>
  number && number.toLocaleString('pt-br', { maximumFractionDigits: 2 })

export const generatePagesArray = (from, to) => {
  return [...new Array(to - from)].map((_, index) => from + index + 1).filter(page => page > 0)
}

export const parseStringBRLToFloat = value =>
  Number.parseFloat(value.replace(/\./g, '').replace(',', '.'))

export const contractHasFeature = (contract, feature) => {
  return contract?.features?.[feature] !== undefined
}

export const getContractFeatureValue = (contract, feature, valueWhenUndefined = false) => {
  const hasFeature = contractHasFeature(contract, feature)
  return hasFeature ? contract.features?.[feature] : valueWhenUndefined
}

export const getPaymentsInfo = async timezone => {
  const dateNow = moment().format('YYYYMMDD')
  const timeNow = moment().format('HH:MM')
  const setTimezone = timezone || 'America/Sao_Paulo'
  try {
    const response = await getPaymentMethodsInfo(dateNow, timeNow, setTimezone)
    if (typeof window !== undefined) {
      sessionStorage.setItem(
        'next_bank_date',
        response.data.content.payment_method.bank_slip.available_at
      )
    }
    return response
  } catch (error) {
    console.log(error)
  }
}

export const isJson = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const parseJSON = param => {
  try {
    return JSON.parse(param)
  } catch {
    return param
  }
}

export const getNewestCheckout = data => {
  const datesArray = data.map(date => date?.created_at)
  const newestDate = getNewestDate(datesArray)
  const newestCheckout = data.find(checkout => checkout?.created_at === newestDate._i)

  return newestCheckout
}

export const getCurrentYear = () => {
  return new Date().getFullYear()
}

export const getDataFromScheduleResponse = response => {
  let schedule = response.data.content.available_times
  let prof_address_slug = response.data.content.prof_address
  let city = response.data.content.city
  let state = response.data.content.state
  let price = response.data.content.price
  let contract_price = response.data.content.contract_price
  let location = response.data.content.location
  let schedule_id = response.data.content.schedule_id
  let date
  let index
  let m
  let dates = []

  let json = {
    schedule: {},
    prof_address_slug: prof_address_slug,
    price: price,
    contract_price: contract_price,
    location: location,
    schedule_id: schedule_id,
    state: state,
    city: city
  }

  if (Object.keys(schedule).length === 0) {
    schedule[moment().format('YYYYMMDD')] = []
  }

  Object.keys(schedule).forEach(function(dateKey, k) {
    dates.push(dateKey)

    schedule[dateKey].forEach(function(timeObj, y) {
      json.duration = timeObj.duration
      date = moment(timeObj.date + timeObj.time, 'YYYYMMDDHH:mm')
      index = date.format('YYYYMMDD')

      if (!json.schedule[index]) {
        json.schedule[index] = [schedule[dateKey][y]]
      } else {
        json.schedule[index].push(schedule[dateKey][y])
      }

      json.schedule[index][json.schedule[index].length - 1].time = date.format('HH:mm')
      json.schedule[index][json.schedule[index].length - 1].date = index
    })
  })

  var today = +moment().format('YYYYMMDD')

  dates = Array.from(new Set(dates)).sort()

  json.dates = dates
    .map(function(e, i) {
      if (+e >= today) {
        m = moment(e, 'YYYYMMDD')

        if (!json.schedule[e]) {
          json.schedule[e] = []
        }

        return {
          weekday: moment.weekdays(m.day()).split('-')[0],
          date: m.format('DD MMMM'),
          timestamp: e
        }
      }
    })
    .filter(Boolean)

  while (json.dates.length < 7 && json.dates.length > 0) {
    m = moment(json.dates[json.dates.length - 1].timestamp).add(1, 'days')

    json.dates.push({
      weekday: moment.weekdays(m.day()).split('-')[0],
      date: m.format('DD MMMM'),
      timestamp: m.format('YYYYMMDD')
    })
  }

  return json
}

export const hasEmojiInString = string => {
  const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
  return regexExp.test(string)
}

export const getLatestRemoteConfigVersion = () =>
  typeof window !== 'undefined' && JSON.parse(localStorage.getItem('remote_config_version'))

export const setLatestRemoteConfigVersion = version =>
  typeof window !== 'undefined' && localStorage.setItem('remote_config_version', version)

export const isMobileOS = userAgent => {
  const platforms = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return platforms.some(platform => userAgent.match(platform))
}

export const sortSchedulesByHour = schedules => {
  return schedules.sort((a, b) => (a.hour.isBefore(b.hour) ? -1 : 1))
}

export const waitFor = (timeInMilliseconds = 1000) => {
  return new Promise(resolve => {
    const id = setTimeout(() => {
      resolve()
      clearTimeout(id)
    }, timeInMilliseconds)
  })
}

export const arrayGroup = (array, subGroupLength) => {
  let index = 0
  const newArray = []

  while (index < array?.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }

  return newArray
}

export const calculateNewDates = (baseDate, daysToAdd) => {
  const newStartDate = moment(baseDate)
    .add(daysToAdd, 'days')
    .format('YYYYMMDD')

  const newEndDate = moment(newStartDate)
    .add(4, 'days')
    .format('YYYYMMDD')

  return { newStartDate, newEndDate }
}

export const withoutSpecialChars = text => text.replace(/[^\w\s]/g, '').toLocaleLowerCase()

export const findKeywords = (text, words) => {
  const emptySpace = ' '
  const lowercased = words.map(word => word.toLocaleLowerCase())
  const keywords = text.split(emptySpace)

  return keywords.map(withoutSpecialChars).filter(keyword => lowercased.includes(keyword))
}

export const writeClipboardText = async text => {
  try {
    await navigator.clipboard.writeText(text)
    toast.success('Chave copiada para a área de transferência!')
  } catch (error) {
    console.error(error)
  }
}

export const getGAIds = () => {
  const cookies = parseCookies()

  return {
    ga_client_id: removeGAPrefix(cookies?.['_ga']),
    ga_session_id: formatGASessionId(cookies?.['_ga_6LDL1BSC1W'])
  }
}
