import { useEffect, useState } from 'react'

const getDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight
})

export const useScreenDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const handleResize = () => setDimensions(getDimensions())

  useEffect(() => {
    if (typeof window !== undefined) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { screenWidth: dimensions.widht, screenHeight: dimensions.height }
}
